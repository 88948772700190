import customerSaleOrderModule from '@/store/customers/saleOrders'
// eslint-disable-next-line no-unused-vars, object-curly-newline
import { computed, getCurrentInstance, ref, watch } from '@vue/composition-api'

export default function useCustomerSaleOrderList(customerId) {
  const vm = getCurrentInstance().proxy

  const id = ref(customerId)

  const MODULE_CONTAINER = 'customers'
  const STORE_MODULE = `${MODULE_CONTAINER}/${id.value}/saleOrders`

  const saleOrders = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/items`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/loading`],
  })
  const totalSaleOrderListTable = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/meta`].total,
  })
  const meta = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/meta`],
  })
  const options = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/options`],
    set: val => vm.$store.dispatch(`${STORE_MODULE}/setOptions`, { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/search`],
    set: val => vm.$store.dispatch(`${STORE_MODULE}/setSearch`, val),
  })

  // onInit
  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], customerSaleOrderModule(customerId))
    }
    vm.$store.dispatch(`${STORE_MODULE}/init`, id.value)
  }

  // onDestroy
  const destroy = () => {
    // TODO: SHOULD DESTROY MODULES?
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  const saleOrderListTable = ref([])

  const tableColumns = [
    { text: 'NUMBER', value: 'number' },
    { text: 'SALESPERSON', value: 'salesperson' },
    { text: 'TOTAL', value: 'totalAmountIncludingTax' },
    { text: 'STATUS', value: 'status' },
    { text: 'ORDER DATE', value: 'orderDate' },
    { text: 'REQUESTED DELIVERY DATE', value: 'requestedDeliveryDate' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const fetchItems = () => vm.$store.dispatch(`${STORE_MODULE}/fetchItems`)
  const setOptions = val => vm.$store.dispatch(`${STORE_MODULE}/setOptions`, val)

  const resolveStatusColor = status => {
    switch (status.toLowerCase()) {
      case 'paid':
        return 'primary'

      case 'open':
        return 'info'

      case 'canceled':
        return 'warning'

      default:
        return 'secondary'
    }
  }

  return {
    saleOrderListTable,
    tableColumns,

    totalSaleOrderListTable,
    loading,

    resolveStatusColor,

    saleOrders,
    meta,
    options,
    setOptions,
    search,

    init,
    destroy,
    fetchItems,
  }
}
