<template>
  <div id="sale-order-list">
    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- role filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="roleFilter"
            placeholder="Select Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            clearable
            dense
            hide-details
          ></v-select>
        </v-col> -->

        <!-- plan filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="planFilter"
            placeholder="Select Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->

        <!-- status filter -->
        <!-- <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Select Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col> -->
      </v-row>

      <!-- <v-divider class="mt-4"></v-divider> -->

      <!-- actions -->
      <!-- <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-row
          align="center"
          align-content="center"
          justify="space-between"
        >
          <v-col
            cols="12"
            md="8"
          >
            <table-search
              v-model="options.q"
              dense
              outlined
              hide-details
              full-width
            />
          </v-col>
        </v-row>
      </v-card-text> -->

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="saleOrders"
        :options="options"
        :search.sync="search"
        :meta="meta"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- number -->
        <template #[`item.number`]="{item}">
          <router-link
            :to="{ name : 'sale-order-view', params : { id : item.id } }"
            class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
          >
            {{ item.number }}
          </router-link>
        </template>

        <!-- totalAmountIncludingTax with currencyCode -->
        <template #[`item.totalAmountIncludingTax`]="{item}">
          <span v-if="item.currencyCode">{{ `${item.currencyCode} ` }}</span>
          <span>{{ item.totalAmountIncludingTax }}</span>
        </template>

        <!-- invoice date -->
        <template #[`item.invoiceDate`]="{item}">
          <span>{{ item.invoiceDate | toLocalDate }}</span>
        </template>

        <!-- due date -->
        <template #[`item.dueDate`]="{item}">
          <span>{{ item.dueDate | toLocalDate }}</span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <div class="d-flex">
            <v-chip
              small
              :color="resolveStatusColor(item.status)"
              class="v-chip-light-bg font-weight-semibold text-uppercase me-1"
              :class="`${resolveStatusColor(item.status)}--text`"
            >
              {{ item.status }}
            </v-chip>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                v-bind="attrs"
                :to="{ name: 'sale-order-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View Sale Order</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>
  </div>
</template>

<script>
import { mdiEyeOutline, mdiExportVariant } from '@mdi/js'

import { onMounted, onUnmounted } from '@vue/composition-api'
import useCustomerSaleOrderList from './useCustomerSaleOrderList'

export default {
  props: {
    customerId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {
      saleOrderListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalSaleOrderListTable,
      loading,
      options,
      saleOrderTotalLocal,

      resolveItemTotalIcon,

      saleOrders,
      meta,
      search,

      resolveStatusColor,

      init,
      destroy,
    } = useCustomerSaleOrderList(props.customerId)

    onMounted(() => {
      init()
    })

    onUnmounted(() => {
      destroy()
    })

    const roleOptions = [
      { title: 'Admin', value: 'admin' },
      { title: 'User', value: 'user' },
    ]

    return {
      saleOrderListTable,
      tableColumns,
      roleFilter,
      planFilter,
      statusFilter,
      totalSaleOrderListTable,
      roleOptions,

      loading,
      options,
      saleOrderTotalLocal,

      // avatarText,
      resolveItemTotalIcon,

      saleOrders,
      meta,
      search,

      // icons
      icons: {
        mdiEyeOutline,
        mdiExportVariant,
      },

      resolveStatusColor,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
