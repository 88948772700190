import customerStoreModule from '@/store/customers/customer'
import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiDnsOutline,
} from '@mdi/js'
// eslint-disable-next-line no-unused-vars
import { computed, getCurrentInstance, ref } from '@vue/composition-api'

export default function useCustomerView(customerId) {
  const vm = getCurrentInstance().proxy

  const id = ref(customerId)

  const MODULE_CONTAINER = 'customers'
  const STORE_MODULE = `${MODULE_CONTAINER}/${id.value}`

  const customer = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/data`],
  })
  const loading = computed({
    get: () => vm.$store.getters[`${STORE_MODULE}/loading`],
  })

  // fetch data
  const fetchData = () => vm.$store.dispatch(`${STORE_MODULE}/fetchData`)
  const fetchSaleOrders = () => vm.$store.dispatch(`${STORE_MODULE}/saleOrders/fetchItems`)
  const fetchSaleInvoices = () => vm.$store.dispatch(`${STORE_MODULE}/saleInvoices/fetchItems`)

  // onInit
  const init = () => {
    if (!vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
      vm.$store.registerModule([MODULE_CONTAINER, id.value], customerStoreModule(customerId))
    }

    vm.$store.dispatch(`${STORE_MODULE}/init`, id.value)

    fetchData()
  }

  // onDestroy
  const destroy = () => {
    // TODO: DESTROY OR NOT
    // if (vm.$store.hasModule([MODULE_CONTAINER, id.value])) {
    //   vm.$store.unregisterModule([MODULE_CONTAINER, id.value])
    // }
  }

  // watch([], () => {
  // loading.value = true
  // fetchData()
  // })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCustomerRoleVariant = role => {
    // if (role === 'subscriber') return 'primary'
    // if (role === 'author') return 'warning'
    // if (role === 'maintainer') return 'success'
    // if (role === 'editor') return 'info'
    if (role === 'admin') return 'error'
    if (role === 'customer') return 'primary'

    return 'primary'
  }

  const resolveCustomerRoleIcon = role => {
    // if (role === 'subscriber') return mdiAccountOutline
    // if (role === 'author') return mdiCogOutline
    // if (role === 'maintainer') return mdiDatabaseOutline
    // if (role === 'editor') return mdiPencilOutline
    if (role === 'admin') return mdiDnsOutline
    if (role === 'customer') return mdiAccountOutline

    return mdiAccountOutline
  }

  const resolveCustomerStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'

    return 'primary'
  }

  const resolveCustomerTotalIcon = total => {
    if (total === 'Total Customers') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Customers') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Customers') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Customers') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    loading,
    customer,

    init,
    destroy,
    fetchData,

    fetchSaleOrders,
    fetchSaleInvoices,

    resolveCustomerRoleIcon,
    resolveCustomerTotalIcon,
    resolveCustomerRoleVariant,
    resolveCustomerStatusVariant,
  }
}
