<template>
  <div class="user-tab-overview">
    <!-- activity timeline -->
    <v-card>
      <v-card-title>
        Details
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-list
          two-line
          multi-line
        >
          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('ID') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.id }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Number') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.number }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Full Name') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.displayName }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Email') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                <a
                  v-if="data.email"
                  :href="`mailto:${data.email}`"
                  target="_blank"
                >
                  {{ data.email }}
                </a>
                <span
                  v-else
                  class="text--secondary"
                >-</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Website') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                <a
                  v-if="data.website"
                  :href="data.website"
                  target="_blank"
                >
                  {{ data.website }}
                </a>
                <span
                  v-else
                  class="text--secondary"
                >-</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Contact') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.contact }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Sales Person Representative') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.salesPersonCode }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Address') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.address }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('City') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.city }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Postal Code') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.postalCode }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            dense
            class="px-0 mb-n2"
          >
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold body-1">
                {{ t('Country') }}
              </v-list-item-title>
              <v-list-item-subtitle class="secondary--text">
                {{ data.country }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiFilePdf } from '@mdi/js'
import { useUtils } from '@/@core/libs/i18n'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useUtils()

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 25) return 'error'
      if (progrss > 25 && progrss <= 50) return 'warning'
      if (progrss > 50 && progrss <= 75) return 'primary'
      if (progrss > 75 && progrss <= 100) return 'success'

      return 'secondary'
    }

    return {
      resolveUserProgressVariant,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
      },

      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}
</style>
