<template>
  <div id="customer-view">
    <v-row>
      <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <customer-bio-panel
          v-if="customer"
          :data="customer"
        ></customer-bio-panel>
      </v-col>

      <v-col
        cols="12"
        md="7"
        lg="8"
      >
        <v-tabs
          v-model="customerTab"
          show-arrows
          class="transparent-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :append="tab.append"
            :to="tab.to"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="customerTab"
          class="transparent-tabs-content mt-5 pa-1"
        >
          <v-tab-item
            v-if="customer"
            value="overview"
          >
            <customer-tab-overview :data="customer"></customer-tab-overview>
          </v-tab-item>
          <v-tab-item
            v-if="customer"
            value="sale-orders"
          >
            <customer-tab-sale-order-list :customer-id="customer.id"></customer-tab-sale-order-list>
          </v-tab-item>
          <v-tab-item
            v-if="customer"
            value="sale-invoices"
          >
            <customer-tab-sale-invoice-list :customer-id="customer.id"></customer-tab-sale-invoice-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- <v-dialog
      v-model="isPlanUpgradeDialogVisible"
      max-width="650"
    >
      <v-card class="py-8">
        <v-card-title class="justify-center text-h5 px-5">
          Upgrade Plan
        </v-card-title>
        <v-card-text class="text-center mt-n2 px-5">
          Choose the best plan for user.
        </v-card-text>
        <v-card-text class="d-flex align-center flex-wrap flex-sm-nowrap mt-5 px-15">
          <v-select
            v-model="selectedPlan"
            label="Choose Plan"
            :items="plansList"
            item-text="text"
            item-value="value"
            outlined
            dense
            hide-details
            class="me-3"
          ></v-select>
          <v-btn
            color="primary"
            class="mt-3 mt-sm-0"
          >
            Upgrade
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-15 pt-8">
          <p class="font-weight-medium text--primary mb-2">
            User current plan is {{ customer.currentPlan }} plan
          </p>
          <div class="d-flex justify-space-between flex-wrap">
            <div class="user-pricing me-3">
              <sup class="primary--text">$</sup>
              <span class="text-5xl font-weight-semibold primary--text">{{ resolveCurrentPlanValue(customer.currentPlan) }}</span>
              <sub class="text-base font-weight-light">/ month</sub>
            </div>
            <v-btn
              color="error"
              outlined
              class="mt-3"
            >
              Cancel Subscription
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'

import { mdiAccountOutline, mdiFileDocumentOutline, mdiReceipt } from '@mdi/js'
import { useRouter } from '@core/utils'
import CustomerBioPanel from './customer-bio-panel/CustomerBioPanel.vue'
import CustomerTabOverview from './customer-tab-overview/CustomerTabOverview.vue'
import CustomerTabSaleOrderList from './customer-tab-sale-orders/CustomerTabSaleOrderList.vue'
import CustomerTabSaleInvoiceList from './customer-tab-sale-invoices/CustomerTabSaleInvoiceList.vue'

import useCustomerView from './useCustomerView'

export default {
  components: {
    CustomerBioPanel,
    CustomerTabOverview,
    CustomerTabSaleOrderList,
    CustomerTabSaleInvoiceList,
  },
  setup() {
    const customerTab = ref(null)

    const { route } = useRouter()

    const {
      loading,
      customer,

      init,
      destroy,
    } = useCustomerView(route.value.params.id)

    // Init
    init()

    // Destroy
    onUnmounted(() => {
      destroy()
    })

    // const customerData = ref({})
    // const selectedPlan = ref('')
    // const isPlanUpgradeDialogVisible = ref(false)

    // store
    //   .dispatch('customer/fetchData', { id: customerId })
    //   .then(response => {
    //     customerData.value = response.data
    //     selectedPlan.value = response.data.currentPlan
    //   })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       customerData.value = {}
    //     }
    //   })

    // const plansList = [
    //   { text: 'Basic - $0/month', value: 'basic' },
    //   { text: 'Standard - $99/month', value: 'standard' },
    //   { text: 'Enterprise - $499/month', value: 'enterprise' },
    //   { text: 'Company - $999/month', value: 'company' },
    // ]

    const tabs = [
      {
        icon: mdiAccountOutline,
        title: 'Overview',
        append: true,
        to: '#overview',
      },
      {
        icon: mdiFileDocumentOutline,
        title: 'Sale Orders',
        append: true,
        to: '#sale-orders',
      },
      {
        icon: mdiReceipt,
        title: 'Sale Invoices',
        append: true,
        to: '#sale-invoices',
      },
    ]

    // ui
    // const resolveCurrentPlanValue = plan => {
    //   if (plan === 'basic') return '0'
    //   if (plan === 'standard') return '99'
    //   if (plan === 'enterprise') return '499'
    //   if (plan === 'company') return '999'

    //   return '0'
    // }

    return {
      tabs,
      customerTab,

      customer,
      loading,

      // plansList,
      // customerData,
      // selectedPlan,
      // resolveCurrentPlanValue,
      // isPlanUpgradeDialogVisible,
    }
  },
}
</script>
