import customerSaleInvoiceModule from './saleInvoices'
import customerSaleOrderModule from './saleOrders'

export default customerId => ({
  namespaced: true,
  modules: {
    saleOrders: customerSaleOrderModule(customerId),
    saleInvoices: customerSaleInvoiceModule(customerId),
  },
  state: () => ({
    id: customerId,
    data: null,
    loading: true,
  }),
  getters: {
    id: state => state.id,
    data: state => state.data,
    loading: state => state.loading,
  },
  mutations: {
    INIT(state, payload) {
      state.id = payload
    },
    SET_DATA(state, payload) {
      state.data = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    init({ commit }, payload) {
      commit('INIT', payload)
    },

    fetchData({ commit }) {
      commit('SET_LOADING', true)

      const { _vm: vm } = this

      vm.$http
        .get(`/v1/customers/${customerId}`)
        .then(res => {
          commit('SET_DATA', res.data?.data?.customer)
        })
        .finally(() => commit('SET_LOADING', false))
    },
  },
})
