<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar
            :color="data.avatar ? '' : 'primary'"
            :class="data.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="120"
            rounded
            class="mb-4"
          >
            <v-img
              v-if="data.avatar"
              :src="require(`@/assets/images/avatars/${data.avatar}`)"
            ></v-img>
            <span
              v-else
              class="font-weight-semibold text-5xl"
            >{{ avatarText(data.displayName) }}</span>
          </v-avatar>

          <span class="mb-2">{{ data.displayName | textEllipsis(90) }}</span>

          <v-chip
            label
            small
            :color="resolveCustomerRoleVariant(data.role)"
            :class="`v-chip-light-bg text-sm font-weight-semibold ${resolveCustomerRoleVariant(data.role)}--text text-capitalize`"
          >
            {{ data.location | textEllipsis(55) }}
          </v-chip>
        </v-card-title>

        <v-card-text class="d-flex justify-center flex-wrap mt-2 pe-sm-0">
          <div class="d-flex align-center me-8 mb-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiFileDocumentOutline }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(data.saleOrdersCount) }}
              </h3>
              <span>Sale Orders</span>
            </div>
          </div>

          <div class="d-flex align-center mb-4 me-4">
            <v-avatar
              size="40"
              rounded
              color="primary"
              class="v-avatar-light-bg primary--text me-3"
            >
              <v-icon
                color="primary"
                size="22"
              >
                {{ icons.mdiReceipt }}
              </v-icon>
            </v-avatar>

            <div>
              <h3 class="text-xl font-weight-medium mb-n1">
                {{ kFormatter(data.saleInvoicesCount) }}
              </h3>
              <span>Sale Invoices</span>
            </div>
          </div>
        </v-card-text>

        <!-- <v-card-actions class="justify-center">
          <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn>
          <v-btn
            color="error"
            outlined
          >
            Suspended
          </v-btn>
        </v-card-actions> -->
      </v-card>

      <!-- edit profile dialog data -->
      <!-- <customer-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :user-data="data"
      ></customer-bio-edit> -->
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheckboxBlankCircle, mdiFileDocumentOutline, mdiReceipt } from '@mdi/js'
import { avatarText, kFormatter } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import useCustomerView from '../useCustomerView'

// import CustomerBioEdit from './CustomerBioEdit.vue'

export default {
  components: {
    // CustomerBioEdit,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },

    // isPlanUpgradeDialogVisible: {
    //   type: Boolean,
    //   required: true,
    // },
  },
  setup() {
    const { resolveCustomerStatusVariant, resolveCustomerRoleVariant } = useCustomerView()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    return {
      resolveCustomerStatusVariant,
      resolveCustomerRoleVariant,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiReceipt,
        mdiFileDocumentOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
